import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import { useMemo, useRef } from 'react';

import {
  type BlockId,
  blockLimitedRequestSubjects,
  blockCustomMetricSettingsState,
  pcBlock_customField_gridData,
  createPCBlockRowKey,
  cleanEmptyFromGrid,
} from 'venn-state';
import { useAgGridStyle } from '../components/grid/AgGridThemeOverrides';
import {
  combinePortfolioRows,
  makeColumnDefs,
  type AllocationRowData,
  makePortfolioRows,
  makeTotalRow,
  combineRowsWithCustomMetrics,
  parsePcField,
  type PCField,
  MAX_CUSTOM_FIELD_CHARS,
} from './usePortfolioComparisonUtils';
import { useSelectedMetrics } from './useSelectedMetrics';
import type { CellValueChangedEvent } from 'ag-grid-community';
import { assertNotNil } from 'venn-utils';
import { useCustomMetadata } from './useCustomMetadata';

export const usePortfolioComparison = (blockId: BlockId) => {
  const agGridStyle = useAgGridStyle();
  const theme = useTheme();
  const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
  const selectedMetrics = useSelectedMetrics();
  const customMetrics = useRecoilValue(blockCustomMetricSettingsState(blockId));
  const customFieldData = useRecoilValue(pcBlock_customField_gridData(blockId));
  const customFieldsRef = useRef(customFieldData);
  customFieldsRef.current = customFieldData;

  const customMetadataMap = useCustomMetadata(subjects);

  const onCellValueChanged = useRecoilCallback(
    ({ set }) =>
      (event: CellValueChangedEvent<AllocationRowData>) => {
        const { columnGroupKey, columnKey } = parsePcField(assertNotNil(event.colDef.field) as PCField);
        const rowKey = createPCBlockRowKey(assertNotNil(event.data.rowData[columnGroupKey]).rowIdPath);

        set(pcBlock_customField_gridData(blockId), (oldGrid) => {
          const newValue = ((event.newValue as unknown) ?? '').toString().substring(0, MAX_CUSTOM_FIELD_CHARS);
          const newRow = { ...oldGrid[rowKey], [columnKey]: newValue };
          const newGrid = { ...oldGrid, [rowKey]: newRow };
          return cleanEmptyFromGrid(newGrid);
        });
      },
    [blockId],
  );

  return useMemo(() => {
    const subjectRowData = subjects.map((subject) => makePortfolioRows(subject, customMetadataMap));
    const rowData = combineRowsWithCustomMetrics(
      combinePortfolioRows(subjects, subjectRowData),
      customMetrics,
      customFieldsRef.current,
    );
    const columnDefs = makeColumnDefs(selectedMetrics, subjects, agGridStyle, theme);

    const pinnedBottomRowData = makeTotalRow(subjects, customMetrics, customFieldsRef.current, rowData);

    return { rowData, columnDefs, onCellValueChanged, pinnedBottomRowData };
  }, [subjects, customMetrics, selectedMetrics, agGridStyle, theme, onCellValueChanged, customMetadataMap]);
};
