import { compact, uniq } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { batchGetFundCustomMetadata, type Portfolio } from 'venn-api';
import type { StudioRequestSubject } from 'venn-state';
import { useMemo } from 'react';
import { useHasFF } from 'venn-utils';

const getFunds = (portfolio: Portfolio): (string | undefined)[] => {
  return [portfolio.fund?.id, ...portfolio.children.flatMap((child) => getFunds(child))];
};

export const useCustomMetadata = (subjects: StudioRequestSubject[]) => {
  const enabled = useHasFF('yield_income_ff');
  const fundIds = useMemo(
    () =>
      uniq(
        compact(subjects.flatMap((subject) => (subject.portfolio ? getFunds(subject.portfolio) : [subject.fund?.id]))),
      ),
    [subjects],
  );

  const { data } = useQuery(
    ['yields', ...fundIds],
    async () => {
      const result = await batchGetFundCustomMetadata(fundIds);
      return result.content;
    },
    {
      enabled,
      suspense: true,
    },
  );

  return data ?? {};
};
